* {
    box-sizing: border-box;
}

#root {
    width: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin-top: 0;
    margin-bottom: 80px;
}

h2 {
    margin-bottom: 0;
    font-size: 20px;
}

p {
    margin: 0;
    color: #687385;
    padding: 8px 0;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 420px;
    padding-top: 40px;
    margin-bottom: 110px;
}

.banner {
    width: 100vw;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #49b386;
    color: #ffffff;
}

.banner h2 {
    margin-bottom: 18px;
    margin-top: 18px;
}

.info-callout {
    position: fixed;
    bottom: 40px;
    border-radius: 4px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 35px 0px rgba(48, 49, 61, 0.08);
    padding: 9px 12px;
    background-color: #ffffff;
}

.dev-callout {
    /* position: fixed; */
    bottom: 110px;
    border-radius: 4px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 35px 0px rgba(48, 49, 61, 0.08);
    padding: 9px 12px;
    background-color: #ffffff;
}

.bold {
    font-weight: 700;
    font-size: 14px;
}

.error {
    font-weight: 400;
    color: #e61947;
    font-size: 14px;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 24px 24px;
    border-top: 1px solid #d8dee4;
}

button {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid gray;
    border-radius: 4px;
    margin-top: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #49b386;
    color: #ffffff;
    width: 420px;
}

button:hover {
    border-color: black;
}
